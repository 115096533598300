import React from 'react';
import { Link } from "react-router-dom";

import profile from '../../assets/images/profile_digital_square.jpg';

import skills from "./skills.json";

function Pills(props) {
  const items = props.array;
  const itemList = items.map((item) =>
    <span key={item.toString()}>{item}</span>             
  );
  return (
    <div className="tags">{itemList}</div>
  )
}

export default class About extends React.Component {
  constructor(props) {
    super(props)
    this.active = "inactive";
  }

render() {
  return (
    <div className="fluid-container">
      <h1>What I'm about</h1>
      <div className="center">
        <img src={profile} alt="Aaron VandenBrink" className="profile" />
      </div>
      <p className="feature">Hi. I'm Aaron. The first thing you may notice, if we meet in person, I'm tall. Not the tallest you've seen, but tall enough that I've heard all the jokes, and have a snappy comeback for most of them. But I'm sure you'll find that I'm also much more than that. I'm <i>friendly</i>, <i>competitive</i>, <i>dedicated</i> &amp; <i>adaptable</i>. I love being a part of a team and working like an underdog. I enjoy the challenge of taking on a variety of rolls, and doing what it takes to get the job done, because it always feels so much better when success comes from working hard and doing things right.</p>

      <h2>What do I do</h2>
      <p>I'm a Product Manager. I really enjoy product and the many threads that make up its parts. I have the technical experience in building amazing apps. I understand the stack they use, and the amount of work it takes to build a new feature (or track down and squash that bug). I have a degree in Marketing. I know how to represent a product, and to sell its vision. I enjoy talking to users, clients &amp; those on the front lines to glean the valuable insights on how a product can be improved. Validating those insights with analytics and KPI improvements.</p> 
      <p></p>

      <h2>Skills</h2>
      <h3 className="title color secondary">Product Management</h3>
      <Pills array={skills['pm']} />

      <h3 className="title color secondary">Software Experience</h3>
      <Pills array={skills['dev']} />
    

      <h2>Interests &amp; Hobbies</h2>
      <p>I play competitive beach volleyball. I'm an Instagram husband (<a href="https://instagram.com/emmahansen" target="_blank" rel="noopener noreferrer">Proof</a>) and an avid photographer.  I enjoy cycling, hiking, gardening, and beers around a good fire. But most often I enjoy spending time with my family.</p>

      <p className="center"><Link to="/contact" className="button secondary large center">Lets Grab a Coffee</Link></p>
      <p></p>
    </div>
  )
}
};