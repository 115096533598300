import React from 'react';
import { Link } from "react-router-dom";

// Assets
import Logo from '../../components/logo/logo';
import stars from './assets/Stars2a.jpg';
import trees1 from './assets/Trees1.png';
import trees2 from './assets/Trees2.png';
import trees3 from './assets/Trees3.png';
import Thinkific from './assets/Thinkific-Logo.png';
import profile from '../../assets/images/profile_digital_square.jpg';

export default class Home extends React.Component {
  state = { activateAnimation: false }
  constructor(props) {
    super(props)
    this.active = "inactive";
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        activateAnimation: true
      })
    }, 200);
  }
  
  render() {
    const show = this.state.activateAnimation ? 'active' : 'inactive';
    return (
      <div>
        <div className={`feature-home ${show}`}>
          <div className="sky-container">
            <img src={stars} alt="Night Sky" className="sky spin" />
          </div>

          <Logo />
          <img src={trees3} alt="Trees Row 1" className="trees row-1" />
          <img src={trees2} alt="Trees Row 2" className="trees row-2" />
          <img src={trees1} alt="Trees Row 3" className="trees row-3" />

        </div>

        <div className="home-title-container">
          <h1>Hey! I'm Aaron.</h1>
        </div>

        <div className="fluid-container">
          <div className="g bio">
            <div className="center">
              <img src={profile} alt="Aaron VandenBrink" className="profile" />
            </div>
            <div>
              <p className="feature">I am a Product Manager from Vancouver, British Columbia.<br/> I am passionate about <span className="green">growing</span> ideas from seed to <span className="red">blooming</span> reality. I enjoy talking to customers to understanding their needs, making data informed decisions, and empowering and enabling teams to generate value quickly.</p>
            </div>
            
          </div>
          
          <div className="fluid-container bio center">
            <a href="https://www.thinkific.com" target="_blank" rel="noopener noreferrer"><img src={Thinkific} alt="Thinkific Logo" width="50%" /></a>
            <p className="feature">I help entrepreneurs to build education powered businesses at <a href="https://www.thinkific.com" target="_blank"rel="noopener noreferrer">Thinkific</a>.</p>
          </div>

          <p className="center bio"><Link to="/about" className="button center secondary large">More about me</Link></p>
        
      </div>

    </div>
    )
  }
};