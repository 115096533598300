import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

// Assets
import './App.scss';
import 'font-awesome/css/font-awesome.css';

// Pages
import Home from './pages/home/Home.js';
import About from './pages/about/About.js';
import Contact from './pages/contact/Contact.js';
import Blog from './pages/blog/Blog.js';

// Components
import Menu from './components/menu/menu.js';
// import Logo from './components/logo/logo.js';

class App extends React.Component {
  
  componentDidMount() {
    setTimeout(function() {
      document.body.className = 'loaded'
    }, 500);
  }
  
  render() {
    return (
      <div id="website-content">
        <Router>
          <Menu />
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/blog" component={Blog} />
        </Router>
        <footer>
          <p className="sub">Site built with love using React, Webpack & SASS</p>
        </footer>

      </div>
    );
  }
}

export default App;
