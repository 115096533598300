import React from 'react';
import { Link } from "react-router-dom";

export default class Menu extends React.Component {
  constructor(prams) {
    super(prams)
    this.toggleMenu = this.toggleMenu.bind(this);
    this.selectMenu = this.selectMenu.bind(this);
    this.state = {menuActivated: false};
  }
  
  toggleMenu(e) {
    this.setState({menuActivated: !this.state.menuActivated});
  }
  
  selectMenu() {
    window.scrollTo(0, 0);
    this.toggleMenu();
  }

  render() {
    const menuActive = this.state.menuActivated ? "active" : "";
    return (
      <menu>
        <button onClick={this.toggleMenu} className={`hamburger simple hide-desktop ${menuActive}`}>
          <span className="a"></span>
          <span className="b"></span>
          <span className="c"></span>
        </button>
        
        <div className={`menu-container ${menuActive}`}>
          <ul className="menu-list">
            <li><Link to="/" onClick={this.selectMenu}>Home</Link></li>
            <li><Link to="/about" onClick={this.selectMenu}>About</Link></li>
            <li><Link to="/contact" onClick={this.selectMenu}>Contact</Link></li>
          </ul>
        </div>
      </menu>
    )
  }
}