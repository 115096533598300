import React from 'react';

export default class Contact extends React.Component {
  state = { activateAnimation: false }
constructor(props) {
  super(props)
  this.active = "inactive";
}
componentDidMount() {
  setTimeout(() => {
    this.setState({
      activateAnimation: true
    })
  }, 500);
}

render() {
  return (
    <div className="fluid-container">
      <h1>Contact</h1>

      <p className="contact-feature"><a href="mailto:aaron.vandenbrink@gmail.com">aaron.vandenbrink@gmail.com</a></p>

      <ul className="social-options">
      <li className="instagram"><a href="https://instagram.com/aaronvandenbrink" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram feature-icon"></i><br />Instagram</a></li>
      <li className="linkedin"><a href="https://www.linkedin.com/in/aaron-vandenbrink-833a1619/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin feature-icon"></i><br />LinkedIn</a></li>
      <li className="facebook"><a href="https://facebook.com/aaronvandenbrink" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook feature-icon"></i><br />Facebook</a></li>
      <li className="github"><a href="https://github.com/avandenbrink" target="_blank" rel="noopener noreferrer"><i className="fa fa-github feature-icon"></i><br />Github</a></li>
      <li className="angellist"><a href="https://angel.co/aaron-vandenbrink" target="_blank" rel="noopener noreferrer"><i className="fa fa-angellist feature-icon"></i><br />AngelList</a></li>
      </ul>
    </div>



  )
}
};