import React from 'react';
//import { Link } from "react-router-dom";

function Article(props) {
  const post = props.post;
  console.log(post);
  return (
    <div key={post.slug.toString()}>
    <h1 dangerouslySetInnerHTML={{__html: post.title.rendered}}></h1>
      <div className="fluid-container" dangerouslySetInnerHTML={{__html: post.excerpt.rendered}}></div>
    </div>
  )
}

function ArticleList(props) {
  const posts = props.posts;
  const postList = posts.map((post) =>
    <Article post={post} />
  );
  return (
    <div>{postList}</div>
  )
}

export default class Blog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {posts: []};
  }

  componentDidMount() {
    fetch("https://emmahansen.ca/wp-json/wp/v2/posts")
      .then(res => res.json())
      .then(
      (result) => {
        this.setState({
          isLoaded: true,
          posts: result
        });
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  render() {
    return (
      <div>
        <h1>Blog</h1>
        <ArticleList posts={this.state.posts} />
      </div>
    )
  }
};