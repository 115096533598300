import React from 'react';
import * as Segment from 'segment-js';
import { ReactComponent as LogoSVG } from './logo.svg';

export default class Logo extends React.Component {
  
  componentDidMount() {
    var logo = document.querySelector('.logo');
    if (logo) {
      var path = logo.querySelector('#logo');
      if (Segment) {
        var pathSegment = new Segment(path, '0', '0');
        pathSegment.draw('0', '100%', 3, {delay: 3});
      }
    }
  }

  render() {
    return (
      <LogoSVG />
    )
  }
}